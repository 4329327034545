.chrono-hamburger svg {
  g {
    @apply origin-center;
    transition: transform 0.2s cubic-bezier(0.25, 1, 0.5, 1);
  }
  path {
    opacity: 1;
    transition:
      transform 0.2s cubic-bezier(0.25, 1, 0.5, 1) 0.2s,
      opacity 0.2s ease 0.2s;
  }

  &.open {
    path {
      transition:
        transform 0.2s cubic-bezier(0.25, 1, 0.5, 1),
        opacity 0s ease 0.2s;
    }
    g {
      transition: transform 0.2s cubic-bezier(0.25, 1, 0.5, 1) 0.2s;
    }
  }

  &.open > {
    path {
      @apply opacity-0;
    }
    g:nth-of-type(1) {
      @apply rotate-45;
      path {
        transform: translate3d(0, 6px, 0);
      }
    }
    g:nth-of-type(2) {
      @apply -rotate-45;
      path {
        transform: translate3d(0, -6px, 0);
      }
    }
  }
}
