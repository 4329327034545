.chrono-scrollbar {
  scrollbar-width: thin; /* Firefox */
  scrollbar-color: oklch(55.55% 0 0 / 40%) transparent; /* Firefox */

  scrollbar-gutter: stable;
  &::-webkit-scrollbar {
    @apply h-3 w-3;
  }
  &::-webkit-scrollbar-track {
    @apply bg-transparent;
  }
  &::-webkit-scrollbar-thumb {
    @apply rounded-[10px];
  }
  &:hover::-webkit-scrollbar-thumb {
    border: 3px solid transparent;
    background-color: var(--tw-shadow-color);
    background-clip: content-box;
    @apply shadow-neutral-500/20 hover:shadow-neutral-500/40;
  }

  @media (max-width: 767px) {
    .chrono-container & {
      scrollbar-gutter: auto;
    }
  }
}

/* Hide scrollbar */
.chrono-no-scrollbar {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */

  &::-webkit-scrollbar {
    @apply hidden; /* Chrome, Safari and Opera */
  }
}
