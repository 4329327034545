@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import './scrollbar.css';
@import './hamburger.css';
@import './cards.css';
@import './code-blocks.css';
@import './api-docs.css';

:root {
  --chrono-navbar-height: 4rem;
  --chrono-menu-height: 3.75rem;
}

/* Styles are scoped to .chrono-content so as not to conflict/override things in the API docs generated by Stoplight */
.chrono-content a,
.chrono-content summary,
.chrono-content button,
.chrono-content input,
.chrono-content [tabindex]:not([tabindex='-1']) {
  @apply outline-none;
  &:focus-visible {
    @apply ring-2 ring-green-200 ring-offset-1 ring-offset-green-300 dark:ring-green-800 dark:ring-offset-green-700;
  }
}
