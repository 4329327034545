.chrono-api-docs {
  /*
   * Hides the 'Send' button for sending requests in the Try It API panel (can't use the hideTryIt option since that
   * also hides the request sample, which we want to show).
   */
  .TryItPanel .SendButtonHolder button {
    display: none;
  }

  /*
   * Hides the export menu since the Swagger definition for docs is munged specifically for docs and shouldn't be
   * used by users to generate clients.
   */
  button[aria-label='Export'] {
    display: none;
  }
}
